import React from 'react';
import {Banner, Button, TextStyle, Stack} from '@shopify/polaris';
import styled from 'styled-components'
const DiscountWarningBannerWrapper = styled.div`
  .WarningBanner__wrapper {
      margin-top: 10px;
      color: #E49113;
  }

  .WarningBanner__button .Polaris-Button {
      background-color: unset;
      border-color: #E1B878;
      color: inherit;
  }

  .WarningBanner__link {
      text-decoration: none;
      color: inherit;
  }

  .WarningBanner__decoratedLink {
      color: inherit;
  }
`
const DiscountWarningBanner = ({isAi = false}) => {

  const content = isAi
    ? 'All AI recommended products will show this compare at price. Add a Shopify Script to your Script Editor app to make the discount apply.'
    : 'This only adds a ‘compare at’ price. Add a Shopify Script to your Script Editor app to apply a discount.';

  return (
    <DiscountWarningBannerWrapper>
    <div className='WarningBanner__wrapper'>
      <Banner status='warning'>
        <Stack vertical>
          <TextStyle>
            {content}
          </TextStyle>
          <div className='WarningBanner__button'>
            <a
              href="https://help.upsellplus.com/en/article/intro-to-discounts-yguu96/"
              target="_blank"
              rel="noopener noreferrer"
              className='WarningBanner__link'
            >
              <Button>
                Go to Script templates
              </Button>
            </a>
          </div>
        </Stack>
      </Banner>
    </div>
    </DiscountWarningBannerWrapper>
  );
};

export default DiscountWarningBanner;